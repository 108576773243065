<template>
    <div style="min-width: 100%">
        <v-sheet  class="px-0 py-0 align-center" style="background-color: transparent;">
            <v-row class="d-flex justify-center" >
                    <v-col cols="12" sm="12" lg="7">
                        <base-card>
                            <v-card-text>
                                <v-row>
                                    <v-col>



                                        <!--:items="['Ομήρου 13 - Νέα Ιωνία', 'Βυζαντίου 37 - Νέος Κόσμος']"-->
                                        <v-select                                                                     
                                            v-model="address_filter"
                                            item-value="code"                                            
                                            :items="myLocations"
                                            label="Διεύθυνση"                                     
                                            style="padding-right: 20px; padding-left: 20px; padding-top: 0px; padding-bottom: 0px; "
                                            :rules="[]"     
                                            required                                                         
                                            :clearable="false"    
                                            hide-details=true     
                                            @change="addressSelected"      
                                        />
                                        
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" class="d-flex justify-start flex-column">                                                                                
                                        <v-menu
                                            bottom
                                            :offset-y="true"
                                            :close-on-content-click="false"
                                            v-model="showTreatmentFilterMenu"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                color="#b1b1b1"   
                                                dark                                             
                                                v-bind="attrs"
                                                v-on="on"
                                                class="text-capitalize mb-2"
                                                small
                                                >
                                                <v-icon>mdi-clipboard-list-outline</v-icon>&nbsp;Επιλέξτε Θεραπεία
                                                </v-btn>
                                            </template>

                                            <v-card style="min-width: 350px;">
                                                <v-card-text>                                                                                                   
                                                    <v-treeview
                                                        v-model="tree"      
                                                        :load-children="fetch"                                          
                                                        :items="items2"
                                                        selected-color="indigo"
                                                        open-on-click
                                                        selectable
                                                        return-object
                                                        expand-icon="mdi-chevron-down"
                                                        on-icon="mdi-checkbox-marked"
                                                        off-icon="mdi-checkbox-blank-outline"
                                                        indeterminate-icon="mdi-checkbox-multiple-marked-outline"
                                                    >
                                                    </v-treeview>
                                                    <div style="width: 100%; " class="text-center">
                                                        <v-btn @click="showTreatmentFilterMenu=false" small color="primary" class="mt-2" style="width: 60%">OK</v-btn>
                                                    </div>                                                    
                                                </v-card-text>
                                            </v-card>                                            
                                        </v-menu>
                                        <!-- <br/> -->
                                        <div v-if="tree.length" style="max-width: 100%;">
                                            <v-chip
                                            v-for="(selection, i) in tree"
                                            :key="i"
                                            color="grey"
                                            dark
                                            small
                                            class="ma-1"
                                            >
                                            <v-icon
                                                left
                                                small
                                            >
                                                mdi-check
                                            </v-icon>
                                            {{ selection.name }}
                                            </v-chip>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="6" class="d-flex justify-start flex-column">                                        
                                        <v-menu
                                            v-model="showDateFilterMenu"
                                            bottom
                                            :offset-y="true"
                                            :close-on-content-click="false"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                color="#b1b1b1" 
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                                class="text-capitalize mb-2"
                                                style="width: 100%"
                                                small
                                                @click="()=>{ hourStartFilter = '08:00'; hourEndFilter= '22:00'}"
                                                >
                                                <v-icon>mdi-calendar</v-icon>&nbsp;Επιλέξτε Ημερομηνία & Ώρα
                                                </v-btn>
                                            </template>

                                            <!-- <v-list>
                                                <v-list-item
                                                v-for="(item, index) in items"
                                                :key="index"
                                                >
                                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list> -->
                                            <v-card style="min-width: 350px;">
                                                <v-card-text class="text-center">
                                                <div class="text-center">
                                                    <v-date-picker 
                                                                    v-model="date" 
                                                                    no-title 
                                                                    @input="save(date)"
                                                                    :events="datesAll"
                                                                    event-color="blue lighten-1"
                                                                    >
                                                        <v-spacer></v-spacer>                                                        
                                                    </v-date-picker>
                                                    <v-select                                                                     
                                                        v-model="hourStartFilter"
                                                        :items="seasons"                 
                                                        label="Ώρα Από"                                     
                                                        style="padding-right: 20px; padding-left: 20px; "
                                                        :rules="[]"     
                                                        required                                                         
                                                        :clearable="false"    
                                                        hide-details=true            
                                                    />
                                                    <v-select                                                                     
                                                        v-model="hourEndFilter"
                                                        :items="seasons"                 
                                                        label="Εώς"                                     
                                                        style="padding-right: 20px; padding-left: 20px; "
                                                        :rules="[]"     
                                                        required                                                         
                                                        :clearable="false"    
                                                        hide-details=true            
                                                    />
                                                    <v-spacer></v-spacer>   
                                                    <v-btn @click="showDateFilterMenu=false" small color="primary" class="mt-2" style="width: 60%">OK</v-btn>
                                                </div>
                                                    <!-- <v-range-slider
                                                    :tick-labels="seasons"
                                                    :value="[0, 1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]"
                                                    min="0"
                                                    max="24"
                                                    ticks="always"
                                                    tick-size="4"
                                                    class="mt-4"
                                                >
                                                    <template v-slot:thumb-label="props">
                                                        {{props.value}}
                                                    <v-icon dark>
                                                        mdi-clock
                                                    </v-icon>
                                                    </template>
                                                </v-range-slider> -->
                                                    <!-- <v-treeview
                                                        v-model="tree"      
                                                        :load-children="fetch"                                          
                                                        :items="items2"
                                                        selected-color="indigo"
                                                        open-on-click
                                                        selectable
                                                        return-object
                                                        expand-icon="mdi-chevron-down"
                                                        on-icon="mdi-checkbox-marked"
                                                        off-icon="mdi-checkbox-blank-outline"
                                                        indeterminate-icon="mdi-checkbox-multiple-marked-outline"
                                                    >
                                                    </v-treeview> -->
                                                </v-card-text>
                                            </v-card>                                            
                                        </v-menu>
                                        <!-- <br/> -->
                                        <div v-if="datesAll.length" style="max-width: 100%; text-align: center;">
                                            <v-chip
                                                v-for="(selection, i) in datesAll"
                                                :key="i"
                                                color="grey"
                                                dark
                                                small
                                                class="ma-1"
                                                >
                                                <v-icon
                                                    left
                                                    small
                                                >
                                                    mdi-calendar-outline
                                                </v-icon>
                                                {{ getDateString(selection) }}
                                            </v-chip>
                                        </div>
                                        <!-- <br/> -->
                                        <div v-if="hourStartFilter || hourEndFilter " style="max-width: 100%; text-align: center;">
                                            <v-chip 
                                                v-if="hourStartFilter"                                                                                               
                                                color="purple"
                                                dark
                                                small
                                                class="ma-1"
                                                >
                                                <v-icon
                                                    left
                                                    small
                                                >
                                                    mdi-clock
                                                </v-icon>
                                                {{ hourStartFilter }}
                                            </v-chip>
                                            -
                                            <v-chip                                                                                                
                                                v-if="hourEndFilter"                                                                                               
                                                color="purple"
                                                dark
                                                small
                                                class="ma-1"
                                                >
                                                <v-icon
                                                    left
                                                    small
                                                >
                                                    mdi-clock
                                                </v-icon>
                                                {{ hourEndFilter }}
                                            </v-chip>
                                        </div>
                                        
                                    </v-col>                                    
                                </v-row>
                            </v-card-text>
                        </base-card>                        
                    </v-col>
                </v-row>
            <div class="container- d-flex justify-center pl-4 pr-4">                
                <v-row class="d-flex justify-center">
                    <v-col cols="12">
                            <v-data-iterator                            
                :items="items"
                :items-per-page.sync="itemsPerPage"
                :page="page"
                hide-default-footer
                no-data-text="Δεν βρέθηκαν Θεραπευτές. Δοκιμάστε με διαφορετικά κριτήρια αναζήτησης"
            >
                <template v-slot:default="props">
                    <v-row class=" d-flex justify-center">
                        <v-col

                            v-for="(item, index) in props.items"
                            :key="index"
                            cols="12"
                            sm="12"
                            md="6"                            
                            lg="3"
                            xl="3"
                        >

                            <list-card
                                @clickcard=" selectedTherapist=props.items[index]; profiledialog=true;"
                                @clickbook=" selectedTherapist=props.items[index]; checkoutdialog=true;"
                                :avatar="item.img"
                                :title="item.title"
                                :sub-title="item.subTitle"                                
                                :rating="item.rating"
                                :ratingCount="item.ratingCount"
                                :isVerified="item.isVerified"
                            />


                        </v-col>
                    </v-row>
                </template>

            </v-data-iterator>
                    </v-col>
 
                </v-row>
                
            </div>

            <v-dialog
                v-model="checkoutdialog"                
                width="auto"
                max-width="800"
                class="d-flex"         
                :fullscreen="false"                  
            >
                <v-card>
                    <v-card-title class="d-flex justify-space-between-" style="background-color: #ffb56b">
                        <v-btn color="lightgrey" fab small @click="checkoutdialog = false" left style="float: left;" class="mr-5 mb-2">
                            <v-icon>
                                mdi-close
                            </v-icon>                            
                        </v-btn>
                        <!-- <h3>Κλείσε Ραντεβού</h3> -->
                        <span class="mr-5" style="color: white"> Νέο Ραντεβού σε:</span>



                        <!--:items="['Ομήρου 13 - Νέα Ιωνία', 'Βυζαντίου 37 - Νέος Κόσμος']"  -->
                        <v-select                                                                     
                            v-model="address_filter"

                            :items="myLocations"                 
                            item-value="code"                            
                            label=""                                                                 
                            :rules="[]"     
                            required                                                         
                            :clearable="false"    
                            hide-details=true      
                            style="max-width: 280px; padding-top: 0px;"      
                            @change="addressSelected"                     
                        />



                    </v-card-title>
                <v-card-text class="px-0">
                    <div class="mx-4 px-4 mb-4">                        
                    </div>
                    <v-spacer></v-spacer>
                    <!-- <span style="font-size: 12px;" class="ml-8">Επιλεγμένος Θεραπευτής:</span> -->
                    <list-card    
                        class="ma-2"
                        v-if="selectedTherapist"                   
                        :avatar="selectedTherapist.img"
                        :title="selectedTherapist.title"
                        :sub-title="selectedTherapist.subTitle"
                        :rating="selectedTherapist.rating"
                        :ratingCount="selectedTherapist.ratingCount"                        
                        :isVerified="selectedTherapist.isVerified"
                        :isFavorite="selectedTherapist.isFavorite"
                        :readmode="true"
                    />
                    <v-spacer></v-spacer>                    
                    <v-expansion-panels v-model="checkoutpanels" accordion focusable>

                        <v-expansion-panel >
                            <v-expansion-panel-header class="d-flex justify-space-between flex-wrap align-middle">
                                <!-- <div>
                                    <v-chip
                                        v-if="checkoutpanels==0"
                                        class="ma-2 flex-wrap"
                                        
                                        color="white"
                                        >
                                        Βήμα 1
                                    </v-chip>
                                    <v-chip
                                        v-else
                                        class="ma-2 flex-wrap"
                                        
                                        >
                                        Βήμα 1
                                    </v-chip>
                                </div>                             -->
                                <span style="font-size: 0.8em;">1. Επιλογή Θεραπείας & Διάρκεια</span>
                            </v-expansion-panel-header>
                            <!--<v-expansion-panel-content class="pt-3">
                                <v-list :v-if="false" two-line>
                                  
                                        <v-list-item v-for="(item, index) in therapistServices.filter(s=>s.selected==true)" :key="item.title" class="px-0">
                                            <template v-slot:default="{ active }">
                                            <v-list-item-content style="font-size: 0.6em !important;">
                                                <v-list-item-title  style="color: black; font-size: 1.4em !important;">
                                                    <v-btn                                                                                                                
                                                        class="mr-1 shadow-sm"
                                                        fab                                                                                                                
                                                        color="lightgrey"   
                                                        x-small
                                                        @click="item.selected=false"                     
                                                    >
                                                        <v-icon>mdi-close</v-icon>
                                                    </v-btn> 
                                                    {{item.title}}
                                                </v-list-item-title>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-list-item-action-text sh>
                                                    <v-select                                                                     
                                                        v-model="item.selectedDuration"
                                                        :items="item.options.map(o=> { return {value: o.duration, text: o.duration + ' / ' + o.price + '€' } })"                 
                                                        label="Διάρκεια / Κόστος"                                     
                                                        style="padding:0px; width: 120px;"
                                                        :rules="[]"     
                                                        required         
                                                        dense                                                
                                                        :clearable="false"    
                                                        hide-details=true                                                             
                                                    />
                                                </v-list-item-action-text>                                             
                                            </v-list-item-action>                                            
                                            </template>
                                            
                                        </v-list-item>
                                        <v-list-item v-if="therapistServices.filter(s=>s.selected==true).length>0">
                                            <v-list-item-content>&nbsp;</v-list-item-content>
                                            <v-list-item-action-text class="v-list-item__title" style="text-align: right; color: black;">
                                                    Σύνολο: {{total}}€
                                                </v-list-item-action-text>
                                        </v-list-item>
                                </v-list>
                                <v-chip
                                    v-for="(selection, i) in therapistServices.filter(s=>s.selected==false)"
                                    :key="i"
                                    :color="selection.selected ? 'purple' : '#ffb56b'"
                                    dark                                    
                                    class="ma-1"
                                    @click="selection.selected=!selection.selected"
                                    >
                                    <v-icon
                                        left
                                        small
                                    >
                                        mdi-plus
                                    </v-icon>
                                    {{ selection.title }} {{ selection.options.find(o=>o.default).price + '€ / ' + selection.options.find(o=>o.default).duration  }}
                                </v-chip>
                            </v-expansion-panel-content>-->
                        </v-expansion-panel>

                        <v-expansion-panel >
                            <v-expansion-panel-header class="d-flex justify-space-between flex-wrap align-left pt-0 pb-0">
                                <!-- <div>
                                    <v-chip
                                        class="ma-2 flex-wrap"
                                        
                                        >
                                        Βήμα 2
                                    </v-chip>
                                </div>                             -->
                                <span style="font-size: 0.8em;">2. Ημ/νία & Ώρα Ραντεβού</span>
                            </v-expansion-panel-header>
                            <!--<v-expansion-panel-content>
                                        <v-menu
                                            v-model="showDateFilterMenuCheckout"
                                            bottom
                                            :offset-y="true"
                                            :close-on-content-click="false"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                color="lightgrey"                                                                               
                                                v-bind="attrs"
                                                v-on="on"
                                                class="text-capitalize mb-2 mt-2"
                                                style="width: 100%"
                                                small
                                                @click="()=>{ hourStartFilter = '08:00'; hourEndFilter= '22:00'}"
                                                >
                                                <v-icon
                                                    right
                                                    dark
                                                >
                                                    mdi-calendar
                                                </v-icon>
                                                &nbsp;Επιλέξτε Διαθεσιμότητα
                                                </v-btn>
                                            </template>
                                            <v-card style="min-width: 350px;">
                                                <v-card-text class="text-center">
                                                <div class="text-center">
                                                    <v-date-picker 
                                                                    v-model="date" 
                                                                    no-title 
                                                                    @input="save(date)"
                                                                    :events="datesAll"
                                                                    event-color="blue lighten-1"
                                                                    >
                                                        <v-spacer></v-spacer>                                                        
                                                    </v-date-picker>
                                                    <v-spacer></v-spacer>   
                                                    <v-btn @click="showDateFilterMenuCheckout=false" small color="primary" class="mt-2" style="width: 60%">OK</v-btn>
                                                </div>                                                   
                                                </v-card-text>
                                            </v-card>                                            
                                        </v-menu>























                    <v-radio-group v-model="selectedTimeslot">
                        <table style="width: 100%; text-align: center;">
                        <tbody>
                            <tr>
                                <td  v-for="(selection, i) in datesAll" :key="i">{{getDateString(selection)}}</td>                                
                            </tr>
                            <tr v-for="timeslot in seasons" :key="timeslot">
                                <td class="text-center"  v-for="(selection, i) in datesAll" :key="i" style="text-align: center;">
                                    <v-radio :disabled="timeslot == '02:00' || timeslot == '03:00'" :value="getDateString(selection) + ' ' + timeslot " color="purple" class="ma-0 pa-0" hide-details hide-spin-buttons >
                                        <template v-slot:label>
                                            <span v-if="timeslot == '02:00' || timeslot == '03:00'" style="text-decoration: line-through;">{{timeslot}}</span>
                                            <span v-else style="color: purple">{{timeslot}}</span>
                                        </template>
                                    </v-radio>
                                </td>                                                                
                            </tr>                            
                        </tbody>
                    </table>
                    </v-radio-group>                    
                            </v-expansion-panel-content>-->
                        </v-expansion-panel>
                            
                        <v-expansion-panel >
                            <v-expansion-panel-header class="d-flex justify-space-between flex-wrap align-left pt-0 pb-0">
                                <span style="font-size: 0.8em;">3. Επισκόπηση & Πληρωμή</span>
                            </v-expansion-panel-header>
                            <!--<v-expansion-panel-content>
                                <v-row>
                                    <v-col cols="12" sm="6" class="pa-0">
                                        <v-list>
                                            <v-list-item>
                                                <h6 class="mb-0"><v-icon>mdi-map-marker</v-icon>&nbsp;Τοποθεσία</h6>
                                            </v-list-item>
                                            <v-list-item class="pl-4">
                                                <p class="mb-0">{{address_filter}}</p>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-col cols="12" sm="6" class="pa-0">
                                        <v-list>
                                            <v-list-item>
                                                <h6 class="mb-0"><v-icon>mdi-calendar</v-icon>&nbsp;Ημερομηνία & Ώρα</h6>
                                            </v-list-item>
                                            <v-list-item class="pl-4">
                                                <p class="mb-0">{{selectedTimeslot}}</p>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row>
                                <v-row class="text-center">
                                    <v-col cols="12" sm="6" class="text-center pa-0">
                                        <v-list>
                                            <v-list-item>
                                                <h6 class="mb-0"><v-icon>mdi-clipboard-list-outline</v-icon>&nbsp;Θεραπείες</h6>
                                            </v-list-item>
                                            <v-list-item class="pl-4">
                                                <p>
                                                    <v-chip
                                                        v-for="(selection, i) in therapistServices.filter(s=>s.selected==true)"
                                                        :key="i"
                                                        :color="'#ffb56b'"
                                                        
                                                        dark         
                                                        small                           
                                                        class="pr-5 mr-1 mb-1"                                                    
                                                        >                                                    
                                                        {{ selection.title }} {{ selection.options.find(o=>o.duration == selection.selectedDuration).price + '€ / ' + selection.options.find(o=>o.default).duration  }}
                                                    </v-chip>
                                                </p>                                                
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-col cols="12" sm="6" class="pa-0">
                                        <v-list>
                                            <v-list-item>
                                                <h6 class="mb-0"><v-icon>mdi-cash-register</v-icon>&nbsp;Σύνολο</h6>
                                            </v-list-item>
                                            <v-list-item class="pl-4">
                                                <h4 class="mb-0">{{total}}€</h4>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-col cols="12" class="pa-0">
                                        <v-list>
                                            <v-list-item class="d-flex justify-center">
                                                <span>Πληρώστε με:</span>
                                            </v-list-item>
                                            <v-list-item class="pl-4 d-flex justify-center flex-column">
                                                
                                                <v-btn color="primary" dark class="text-capitalize" width="320">
                                                    <v-icon>mdi-credit-card-fast-outline</v-icon>
                                                    &nbsp;&nbsp;&nbsp;Χρεωστική / Πιστωτική Κάρτα
                                                </v-btn>
                                                <br/>
                                                <v-btn color="black" dark class="text-capitalize" width="320">
                                                    <v-icon>mdi-bank-outline</v-icon>
                                                    &nbsp;&nbsp;&nbsp;Μεταφορά σε τράπεζα
                                                </v-btn>
                                                <br/>
                                                <v-btn color="#595959" dark class="text-capitalize" width="320">
                                                    <v-icon>mdi-account-cash-outline</v-icon>
                                                    &nbsp;&nbsp;&nbsp;Μετρητά 
                                                </v-btn>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row>
                                
                            </v-expansion-panel-content>-->
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <p style="color: red; margin: 15px;">Error Occured [{{ (new Date()).toString() }}] : <br/> VIVAPAY Error EXT3454KEY (Invalid Api Key), wrong domain certi...</p>
                </v-card-text>
                <!-- <v-card-actions>
                    <v-btn color="primary" block @click="dialog = false">Close Dialog</v-btn>
                </v-card-actions> -->
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="profiledialog"                
                width="auto"
            >
                <v-card>
                <v-card-text v-if="selectedTherapist">    
                    <profile-details :details="selectedTherapist.details" :selectedDates="datesAll" @clickbook="checkoutdialog=true;"></profile-details>                
                </v-card-text>
                <!-- <v-card-actions>
                    <v-btn color="primary" block @click="dialog = false">Close Dialog</v-btn>
                </v-card-actions> -->
                </v-card>
            </v-dialog>
        </v-sheet>        
    </div>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import avatarGroupCard from '@/components/card/AvatarGroupCard';
import {VueperSlides, VueperSlide} from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import ListCardThree from '@/components/card/listCard/ListCardThree';
import ProfileDetails from '@/views/mng/home/profiledetails';

import {api, srvApi, srvApiNoAuth, PATH, getAssetUrl} from "src/api/index";

import GoogleMapX from "@/components/core/GoogleMapX";

import ListCard from '@/components/card/listCard/ListCard';

import TherapistListCard from "@/components/home/TherapistListCard";

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Find your Session'
    },
    components: {
        avatarGroupCard,
        VueperSlides, 
        VueperSlide,
        GoogleMapX,
        'list-card-three': ListCardThree,
        'list-card': TherapistListCard,
        'profile-details': ProfileDetails
    },
    



    data() {
        return {
            selectedServices: [],
            therapistServices: [
                {
                    title: "Μασάζ Προσώπου",                    
                    options: [
                        { //€
                            price: "15",
                            duration: "30'",
                            default: false,
                        },
                        { //€
                            price: "30",
                            duration: "60'",
                            default: true,
                        }
                    ],
                    selected: false,
                    selectedDuration: "60'"
                },
                {
                    title: "Μασάζ Πλάτης",
                    options: [
                        { //€
                            price: "20",
                            duration: "30'",
                            default: false,
                        },
                        { //€
                            price: "20",
                            duration: "60'",
                            default: true,
                        }
                    ],
                    selected: false,
                    selectedDuration: "60'"
                },
                {
                    title: "Μασάζ Αυχένα",
                    options: [
                        { //€
                            price: "10",
                            duration: "15'",
                            default: false,
                        },
                        { //€
                            price: "20",
                            duration: "30'",
                            default: true,
                        }
                    ],
                    selected: false,
                    selectedDuration: "30'"
                },
                {
                    title: "Shiatsu",
                    options: [
                        { //€
                            price: "10",
                            duration: "15'",
                            default: false,
                        },
                        { //€
                            price: "20",
                            duration: "30'",
                            default: true,
                        }
                    ],
                    selected: false,
                    selectedDuration: "30'"
                },
                {
                    title: "Λεμφικό Μασάζ",
                    options: [
                        { //€
                            price: "30",
                            duration: "30'",
                            default: false,
                        },
                        { //€
                            price: "40",
                            duration: "60'",
                            default: true,
                        },
                        { //€
                            price: "60",
                            duration: "90'",
                            default: false,
                        }                    
                    ],
                    selected: false,
                    selectedDuration: "60'"
                },
            ],
            selectedTherapistServices: [],

            selectedTimeslot: null,


            checkoutpanels: 0,

            checkoutdialog: false,
            profiledialog: false,
            breweries: [], 
            isLoading: false,
            tree: [],
            //types: ['Μασάζ','Φυσιοθεραπεία', 'Ρεφλεξολογία'],

            hourStartFilter: null,
            hourEndFilter: null,

            showTreatmentFilterMenu: false,
            showDateFilterMenu: false,
            showDateFilterMenuCheckout: false,

            selectedTherapist: null,
            

            date: null,
            datesAll: [],

            seasons: ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00','24:00'],


            address_filter: null,


            itemsPerPageArray: [],
            search: '',
            filter: {},
            sortDesc: false,
            page: 1,
            itemsPerPage: 1000,
            sortBy: 'name',
            keys: [
                'Name',
                'Calories',
                'Fat',
                'Carbs',
                'Protein',
                'Sodium',
                'Calcium',
                'Iron'
            ],
            items: [

                

                /*{
                    img: require('@/assets/images/avatars/001-man.svg'),
                    title: 'Timothy clarkson',
                    subTitle: '',
                    progressLinearColor: 'primary',
                    rating: 3,
                    ratingCount: 34,
                },*/
                /*{
                    img: require('@/assets/images/avatars/002-woman.svg'),
                    title: 'Zac Clarkson',
                    subTitle: '',
                    progressLinearColor: 'success',
                    isVerified: true
                },
                {
                    img: require('@/assets/images/avatars/003-man-1.svg'),
                    title: 'Zac Clarkson',
                    subTitle: 'I am a therapist specialized in athletic massage.',
                    progressLinearColor: 'danger',
                    rating: 3,
                },
                {
                    img: require('@/assets/images/avatars/007-woman-2.svg'),
                    title: 'Timothy clarkson',
                    subTitle: '',
                    progressLinearColor: 'primary'
                },
                {
                    img: require('@/assets/images/avatars/006-woman-1.svg'),
                    title: 'Zac Clarkson',
                    subTitle: '',
                    progressLinearColor: 'success'
                },
                {
                    img: require('@/assets/images/avatars/003-man-1.svg'),
                    title: 'Zac Clarkson',
                    subTitle: '',
                    progressLinearColor: 'danger',
                    rating: 2,
                    ratingCount: 0,
                },
                {
                    img: require('@/assets/images/faces/1.jpg'),
                    title: 'Timothy clarkson',
                    subTitle: '',
                    progressLinearColor: 'primary'
                },
                {
                    img: require('@/assets/images/faces/2.jpg'),
                    title: 'Zac Clarkson',
                    subTitle: '',
                    progressLinearColor: 'success'
                },
                {
                    img: require('@/assets/images/faces/3.jpg'),
                    title: 'Zac Clarkson',
                    subTitle: '',
                    progressLinearColor: 'danger'
                },
                {
                    img: require('@/assets/images/avatars/001-man.svg'),
                    title: 'Timothy clarkson',
                    subTitle: 'An experienced masseuse with 22 years in the business.',
                    progressLinearColor: 'primary'
                },
                {
                    img: require('@/assets/images/avatars/002-woman.svg'),
                    title: 'Zac Clarkson',
                    subTitle: '',
                    progressLinearColor: 'success'
                },
                {
                    img: require('@/assets/images/avatars/003-man-1.svg'),
                    title: 'Zac Clarkson',
                    subTitle: '',
                    progressLinearColor: 'danger'
                },

               */
            ],
        }
    },
    computed: {
        //...mapGetters([{types: 'refSystemServiceGroups'}]),        
        ...mapGetters(['refSystemServiceGroups', 'refSystemServices', 'myLocations','myCurrentLocation']),
        total() {
            let selected = this.therapistServices.filter(s=>s.selected);
            let total=0;
            for(let s of selected) {
                for(let o of s.options) {
                    if(s.selectedDuration == o.duration) {
                        total += parseInt(o.price);
                    }
                }                 
            }
            return total;
        },
        items2() {
        let localePostfix = this.$root.$i18n.locale;
        const children = this.refSystemServiceGroups == null ? [] : this.refSystemServiceGroups.map(type => ({
          id: type.code,
          name: this.getName(type["title_"+localePostfix]),
          children: this.getChildren(type),
        }))
        //console.log(JSON.stringify(children, null, 2));
        return children;

        // return [{
        //   id: 1,
        //   name: 'All Breweries',
        //   children,
        // }]
      },
      shouldShowTree () {
        return this.breweries.length > 0 && !this.isLoading
      },
        // numberOfPages() {
        //     return Math.ceil(this.items.length / this.itemsPerPage)
        // },
        // filteredKeys() {
        //     return this.keys.filter((key) => key !== 'Name')
        // }
    },
    watch:  {
        myCurrentLocation(newVal) {
            if(newVal) {
                if(this.address_filter == null) {
                    this.address_filter = newVal.code;   
                }                
            }
        },
    //     breweries (val) {
    //         this.types = val.reduce((acc, cur) => {
    //             const type = cur.brewery_type

    //             if (!acc.includes(type)) acc.push(type)

    //             return acc
    //         }, []).sort()
    //   },
    },
    methods: {        
        // nextPage() {
        //     if (this.page + 1 <= this.numberOfPages) this.page += 1
        // },
        // formerPage() {
        //     if (this.page - 1 >= 1) this.page -= 1
        // },
        // updateItemsPerPage(number) {
        //     this.itemsPerPage = number
        // }
        async searchTherapists() {
            const rspTherapists = await srvApi.get(PATH.BOOKING_THERAPISTS);
            this.items.push(...rspTherapists.data.map(t=>({
                img: getAssetUrl(t.profile_photo), //require('@/assets/images/avatars/001-man.svg'),
                title: t.firstname + " " + t.lastname,
                subTitle: t.short_description,
                progressLinearColor: 'primary',
                rating: t.rating,
                ratingCount: t.rating_count,
                details: {...t}
            })))
        },
        ...mapMutations(['setMyCurrentLocation']),
        addressSelected: function(loca) {
            const selectedLocation = this.myLocations.find(x=>x.code==loca);
            if(selectedLocation) {
                this.setMyCurrentLocation(selectedLocation);   
            }            
        },
        save: function (date) {
        var index = this.datesAll.findIndex(x => x===date)

        if (index === -1){
            this.datesAll.push(date);
        }
        else {
            this.datesAll.splice(index,1);
        }
        },
        fetch () {
            if (this.breweries.length) return

            this.breweries = [
            {
                id: 10,
                brewery_type: "Μασάζ",
                name: "Μασάζ Πόδια",
            },
            {
                id: 2,
                brewery_type: "Μασάζ",
                name: "Μασάζ Κεφάλι",
            },
            {
                id: 3,
                brewery_type: "Μασάζ",
                name: "Μασάζ Πλάτη",
            },
            {
                id: 4,
                brewery_type: "Μασάζ",
                name: "Shiatsu",
            },
            {
                id: 5,
                brewery_type: "Φυσιοθεραπεία",
                name: "Physio Μέση",
            },
            {
                id: 6,
                brewery_type: "Φυσιοθεραπεία",
                name: "Θεραπεία Αυχένα",
            },
            {
                id: 7,
                brewery_type: "Φυσιοθεραπεία",
                name: "Αποκατάσταση Γονάτου",
            },
            {
                id: 8,
                brewery_type: "Μασάζ",
                name: "Hot Stone Massage",
            },
            {
                id: 9,
                brewery_type: "Μασάζ",
                name: "CBD Oil Massage",
            },
            
        ]
        return;

        return fetch('https://api.openbrewerydb.org/breweries')
          .then(res => res.json())
          .then(data => (this.breweries = data))
          .catch(err => console.log(err))
      },
        // getChildren (type) {
        //     const breweries = []
        //     for (const brewery of this.breweries) {
        //         if (brewery.brewery_type !== type) continue
        //         breweries.push({
        //             ...brewery,
        //             name: this.getName(brewery.name),
        //         })
        //     }
        //     return breweries.sort((a, b) => {
        //         return a.name > b.name ? 1 : -1
        //     })
        // },
        getChildren (type) {
            let localePostfix = this.$root.$i18n.locale;
            const tempChildren = []
            if(this.refSystemServices) {
                for (const chi of this.refSystemServices) {
                    if (chi.service_type_group !== type.code) continue
                    tempChildren.push({
                        ...chi,
                        id: chi.code,
                        name: this.getName(chi["title_" + localePostfix]),
                    })
                }
            }            
            // return tempChildren.sort((a, b) => {
            //     return a.name > b.name ? 1 : -1
            // })
            return tempChildren;
        },
        getName (name) {
            return `${name.charAt(0).toUpperCase()}${name.slice(1)}`
        },

        getDateString: function(dt_string) {
            var weekday=new Array(7);
            weekday[1]="Mon";
            weekday[2]="Tue";
            weekday[3]="Wed";
            weekday[4]="Thu";
            weekday[5]="Fri";
            weekday[6]="Sat";
            weekday[0]="Sun";
            
            var dt = new Date(dt_string);
            const dayWeek = dt.getUTCDay();
            
            return `${weekday[dayWeek]}, ${dt.getUTCDate()}/${dt.getUTCMonth()+1}`;
        },

        initializeMyGeolocation(position) {
            console.log(JSON.stringify(position.coords));
        }
    },

        async mounted() {            

            // function getLocation() {
                
            // }            
            if (navigator.geolocation) {                
                //navigator.geolocation.getCurrentPosition(showPosition);
                navigator.geolocation.getCurrentPosition(this.initializeMyGeolocation, null, {/*frequency:5000,*/ maximumAge: 0, timeout: 100, enableHighAccuracy:true});
            } else {
                console.log("Geolocation is not supported by this browser.");
            }

            await this.searchTherapists();
                        
        }
}
</script>
<style lang="scss" scoped>
.ul-widget-profile-img {
    position: relative;
}
.v-radio {
    display: block !important;
}
.ul-widget-profile-img:after {
    // content: "";
    // position: absolute;
    // top: 44px;
    // left: 50%;
    // width: 1px;
    // height: calc(100% - 30px);
    // background: #B3C0CE;
    content: '';
    position: absolute;
    top: 44px;
    left: 50%;
    width: 1px;
    height: calc(100% - 30px);
    background: #b3c0ce;
}
</style>
