<template>
    <v-card @click.prevent="$emit('clickcard')">
        <v-card-text>
            <div
                class="d-flex justify-space-between align-center flex-wrap mb-4"
            >
                <div class="d-flex align-center">
                    <img
                        class="mr-md rounded-circle mr-2"
                        height="44"
                        width="44"
                        :src="avatar"
                    />
                    <div class="d-flex justify-space-between align-start" style="flex-direction: column;">
                        <span v-if="readmode" style="font-size: 12px;">Επιλεγμένος Θεραπευτής</span>
                        <p class="ma-0">
                            <a
                                href="#"
                                class="text--primary font-weight-medium mb-1 hover:info--text"
                            >
                                {{ title }}
                            </a>
                        </p>                        
                        <div class="text--disabled caption ma-0 ">
                            <div v-if="ratingCount" style="display: inline-block">
                                <v-rating           
                                    style="display: inline-block;"                         
                                    v-model="rating"
                                    background-color="primary"
                                    color="primary"                                
                                    dense                                
                                    small
                                ></v-rating>
                                <span class="ml-1" style="font-size: 0.8em">({{ratingCount}})</span>
                            </div>                                                                              
                        </div>
                    </div>
                </div>
                <div class="">                           
                    <v-tooltip
                        top
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                v-if="verified"
                                class="mr-1 shadow-sm"
                                fab
                                x-small
                                dark
                                color="primary"                        
                            >
                                <v-icon>mdi-check-decagram</v-icon>
                            </v-btn>           
                        </template>      
                        Verified                  
                    </v-tooltip>                    
                    <v-btn
                        v-if="favorite"
                        class="mr-2 shadow-sm"
                        fab
                        color="danger"
                        x-small
                        dark
                        @click.stop="favorite=!favorite"
                    >
                        <v-icon>mdi-heart</v-icon>
                    </v-btn>
                    <v-btn
                        v-else
                        class="mr-2 shadow-sm"
                        fab                        
                        x-small        
                        @click.stop="favorite=!favorite"
                    >
                        <v-icon>mdi-heart-outline</v-icon>
                    </v-btn>
                    <!-- <v-btn class="mr-2 shadow-sm" fab x-small dark color="info">
                        <v-icon>{{ buttonIconTwo }}</v-icon>
                    </v-btn> -->                    
                </div>
            </div>
            <v-divider />
            <div
                class="d-flex justify-space-between align-center flex-wrap mt-2"                
            >
                <div class="mb-2-" style="min-width: 100%;">
                    <!-- <div class="d-flex justify-space-between">
                        <p class="pr-10">
                            Progressbar
                        </p>
                        <p>40%</p>
                    </div>
                    <v-progress-linear
                        :color="progressLinearColor"
                        value="70"
                    /> -->
                    <div class="d-flex justify-space-between align-center">
                        <p class="pr-2 text--disabled caption ma-0 " style="color: rgba(0, 0, 0, 0.68) !important;">
                            {{ subTitle }}
                        </p>
                        <!-- <p>40%</p> -->
                        <v-btn v-if="!readmode" class="ma-2 text-capitalize" color="#eb8e45" style="opacity: 0.9; color: white" depressed dark @click.stop="$emit('clickbook')">
                            <v-icon>
                                mdi-file-document-plus-outline
                            </v-icon>
                            &nbsp;
                            Ραντεβού
                        </v-btn>
                    </div>
                </div>
                <div class="d-flex justify-end">
                    <!-- <v-btn class="ma-2" color="" small depressed>
                        {{ footerBtn }}
                    </v-btn> -->
                    <!-- <v-btn class="ma-2" color="#eb8e45" style="opacity: 0.9; color: white" small depressed dark>
                        Book Session
                    </v-btn> -->
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    props: {
        // Floating Button icon Color
        selectedTherapist: {
            type: Object,
            default: null
        },
        avatar: {
            type: String,
            default: require('@/assets/images/avatars/004-bald.svg')
        },
        title: {
            type: String,
            default: 'Timothy Clarksons'
        },
        subTitle: {
            type: String,
            default: 'Vue Developer'
        },
        isFavorite: {
            type: Boolean,
            default: false
        },
        isVerified: {
            type: Boolean,
            default: false
        },
        ratingCount: {
            type: Number,
            default: 0
        },
        rating: {
            type: Number,
            default: 0
        },
        readmode: {
            type: Boolean,
            default: false
        }
        // footerBtn: {
        //     type: String,
        //     default: 'Chat'
        // },
        // footerBtnTwo: {
        //     type: String,
        //     default: 'Profile'
        // },
        // buttonColorOne: {
        //     type: String,
        //     default: ''
        // },
        // buttonIconOne: {
        //     type: String,
        //     default: ''
        // },
        // buttonIconTwo: {
        //     type: String,
        //     default: ''
        // },
        // buttonColorTwo: {
        //     type: String,
        //     default: ''
        // },
        // buttonIconThree: {
        //     type: String,
        //     default: 'mdi-google'
        // },
        // buttonColorThree: {
        //     type: String,
        //     default: ''
        // },
        // progressLinearColor: {
        //     type: String,
        //     default: 'primary'
        // }
    },
    data() {
        return {
            help_rating: 4,
            favorite: this.isFavorite,  
            verified: this.isVerified,                      
        }
    }
}
</script>
